import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { USER } from "../../types/user";
import LoadingIcon from "../../common/LoadingIcon";
import { error_message } from "../../constants/Errors";

/**
 * UserTable - A component for displaying a table of user information with select, filter, and pagination capabilities.
 *
 * This component renders a table of user data, including user name, department, email, and available applications.
 * It also provides functionality to select individual users or select all users, and links to the user's detail page.
 * A default image or the user's profile image is displayed next to their name.
 *
 * Props:
 * - `onUserSelect` (function): A callback function invoked when a user is selected or deselected.
 * - `onDeselectAll` (function): A callback function invoked when the select-all checkbox is toggled off.
 * - `userInformation` (USER[]): An array of user objects containing user data to be displayed in the table.
 *
 * State:
 * - `selectedUsers` (string[]): An array of selected user IDs.
 * - `selectAll` (boolean): A state indicating whether all users are selected.
 * - `userData` (USER[]): A state for storing the user data to be displayed in the table.
 * - `imageLoading` (boolean): A state to track if the profile image is loading for each user.
 *
 * Data Handling:
 * - The `userData` state is updated whenever the `userInformation` prop changes.
 * - The `handleUserSelect` function toggles the selection of a user and updates the `selectedUsers` state.
 * - The `handleSelectAll` function selects or deselects all users, updating the `selectedUsers` state accordingly.
 *
 * Display:
 * - Displays user information, such as name, department, email, and available applications.
 * - Shows a loading indicator while profile images are being loaded.
 * - Links the user name to a detail page for more information.
 *
 * Usage:
 * <UserTable
 *   onUserSelect={handleUserSelect}
 *   onDeselectAll={handleDeselectAll}
 *   userInformation={userInformation}
 * />
 */

const UserTable = ({
  onUserSelect,
  onDeselectAll,
  userInformation,
  allSelected,
  setAllSelected,
}) => {
  const [selectedUsers, setSelectedUsers] = useState([]); // Selected users state
  const [selectAll, setSelectAll] = useState<boolean>(false); // Select all state
  const [userData, setUserData] = useState<USER[]>([]); // Local state for user data
  const [imageLoading, setImageLoading] = useState<boolean>(true); // Loading state for images

  const currentUsers: USER[] = userData;

  useEffect(() => {
    setUserData(userInformation);
  }, [userInformation]);

  useEffect(() => {
    setSelectAll(allSelected);
  }, [allSelected]);

  const handleUserSelect = (userId) => {
    setSelectedUsers((prevSelected) => {
      if (prevSelected.includes(userId)) {
        return prevSelected.filter((id) => id !== userId);
      } else {
        return [...prevSelected, userId];
      }
    });
    onUserSelect(userId);
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setAllSelected(!selectAll);
    if (!selectAll) {
      const allUserIds = currentUsers.map((user) => user.id);
      allUserIds.forEach((userId) => onUserSelect(userId, true));
      setSelectedUsers(allUserIds);
    } else {
      setSelectedUsers([]);
      onDeselectAll();
    }
  };

  return (
    <div className="w-full">
      <div className="border border-text-field-border rounded-2xl overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full table-fixed mayo-card-header rounded-2xl">
            <thead className="border-b border-text-field-border">
              <tr>
                <th className="w-16 p-4 text-center">
                  <input
                    type="checkbox"
                    className="w-4 h-4"
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                </th>
                <th className="p-5 text-left label-light-blue-xl min-w-[10] md:w-10">
                  ID
                </th>
                <th className="p-5 text-left label-light-blue-xl min-w-[150px] md:w-40">
                  氏名
                </th>
                <th className="text-left label-light-blue-xl min-w-[150px] md:w-40">
                  部署
                </th>
                <th className="text-left label-light-blue-xl min-w-[200px] lg:w-64">
                  メールアドレス
                </th>
                <th className="text-left label-light-blue-xl min-w-[150px] lg:w-48">
                  利用可能アプリ
                </th>
              </tr>
            </thead>
            <tbody className="mayo-card-body divide-y divide-text-field-border rounded-2xl">
              {currentUsers?.length === 0 ? (
                <tr>
                  <td></td>
                  <td colSpan="5" className="text-center py-4">
                    {error_message.user.no_data}
                  </td>
                </tr>
              ) : (
                currentUsers?.map((user) => (
                  <tr key={user.id}>
                    <td className="p-4 text-center">
                      <input
                        type="checkbox"
                        className="w-4 h-4"
                        checked={selectedUsers.includes(user.id)}
                        onChange={() => handleUserSelect(user.id)}
                      />
                    </td>
                    <td className="py-4 text-xl text-gray-900 min-w-[10px]">
                      {user.id || ""}
                    </td>
                    <td className="py-5 flex items-center gap-2.5 min-w-[20px]">
                      <div className="relative w-16 h-16">
                        {selectedUsers?.profile_image_url && imageLoading ? (
                          <div className="absolute inset-0 flex items-center justify-center">
                            <LoadingIcon
                              width={8}
                              height={8}
                              textSize="text-sm"
                            />
                          </div>
                        ) : (
                          <div className="absolute p-10 inset-0 flex items-center justify-center bg-gray-200 rounded-full">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="12"
                                cy="8"
                                r="4"
                                stroke="black"
                                strokeWidth="2"
                              />
                              <path
                                d="M4 20C4 16.6863 6.68629 14 10 14H14C17.3137 14 20 16.6863 20 20"
                                stroke="black"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        )}
                        {user.profile_image_url ? (
                          <img
                            src={user.profile_image_url}
                            onLoad={() => setImageLoading(false)}
                            className="min-w-16 min-h-16 max-w-16 max-h-16 mr-10 rounded-full object-cover"
                          />
                        ) : (
                          <svg
                            className="w-16 h-16"
                            viewBox="0 0 100 100"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="100"
                              height="100"
                              rx="50"
                              fill="#D9D9D9"
                            />
                            <path
                              d="M50 50.375C42.373 50.375 36.25 44.252 36.25 36.625C36.25 29.1055 42.373 22.875 50 22.875C57.5195 22.875 63.75 29.1055 63.75 36.625C63.75 44.252 57.5195 50.375 50 50.375ZM55.3711 55.5312C65.6836 55.5312 74.0625 63.9102 74.0625 74.2227C74.0625 76.2637 72.3438 77.875 70.3027 77.875H29.5898C27.5488 77.875 25.9375 76.2637 25.9375 74.2227C25.9375 63.9102 34.209 55.5312 44.5215 55.5312H55.3711Z"
                              fill="#8E8E8E"
                            />
                          </svg>
                        )}
                      </div>
                      <Link to={`/users/${user.id}`}>
                        <div className="flex flex-col justify-center">
                          <div className="mayo-text-user-name">
                            {user.last_name} {user.first_name}
                          </div>
                          <div>
                            {user.last_furigana_name} {user.first_furigana_name}{" "}
                            {/* changed from kana name to furigana name */}
                          </div>
                        </div>
                      </Link>
                    </td>
                    <td className="py-4 text-xl text-gray-900 min-w-[150px]">
                      {user.tenant_department_id}
                    </td>
                    <td className="py-4 text-xl text-gray-900 min-w-[200px]">
                      {user.email || "登録されていません。"}
                    </td>
                    <td className="py-4 text-xl text-gray-900 min-w-[150px]">
                      {user.user_apps && user.user_apps.length > 0 ? (
                        user.user_apps.map((app, index) => (
                          <span key={index}>
                            {app.app_name}
                            {index < user.user_apps.length - 1 && ", "}
                          </span>
                        ))
                      ) : (
                        <span>{error_message.user.no_app_registered}</span>
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UserTable;
